document.addEventListener("DOMContentLoaded", () => {
  const elements = Array.from(
    document.querySelectorAll(".js-accordion-salary-list")
  )
  if (elements.length === 0) return

  const links = Array.from(
    document.querySelectorAll(".om2-p-salary-list__item__corporation-link")
  )
  links.forEach((link) => {
    link.onclick = (event) => {
      event.stopPropagation()
    }
  })

  const switchDisplay = (obj) => {
    const target = obj.currentTarget
    const accordion_content_class = target.dataset.accordion
    const accordion_contents = Array.from(
      document.getElementsByClassName(accordion_content_class)
    )
    const accordion_icon_up = document.getElementById(
      accordion_content_class + "-icon-up"
    )
    const accordion_icon_down = document.getElementById(
      accordion_content_class + "-icon-down"
    )
    if (accordion_contents[0].classList.contains("om2-u-dpn")) {
      accordion_contents.forEach((accordion_content) => {
        accordion_content.classList.remove("om2-u-dpn")
      })
      accordion_icon_up.classList.remove("om2-u-dpn")
      accordion_icon_down.classList.add("om2-u-dpn")
    } else {
      accordion_contents.forEach((accordion_content) => {
        accordion_content.classList.add("om2-u-dpn")
      })
      accordion_icon_up.classList.add("om2-u-dpn")
      accordion_icon_down.classList.remove("om2-u-dpn")
      if (
        target.getBoundingClientRect().top < 0 ||
        window.innerHeight < target.getBoundingClientRect().top
      ) {
        target.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  elements.forEach((element) => {
    element.addEventListener("click", switchDisplay)
  })
})
